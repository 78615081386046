<template>
  <div>
    <Loader :class="{ show: globalStore.isLoading }" />
    <main class="main-content mt-0">
      <div class="min-vh-100 overflow-hidden d-flex justify-content-center align-items-center p-0" style="background: #eeeeee;">
        <div class="row w-100 d-flex justify-content-center">
          <div class="">
            <slot />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import Loader from "~/components/Layout/Loader.vue";

const { globalStore } = useNuxtApp();
const route = useRoute();
const { runtimeConfig } = useNuxtApp();

if (route.meta.title) useHead({ titleTemplate: titleChunk => `${route.meta.title} ${runtimeConfig.public.titleSeparator} ${runtimeConfig.public.siteName}` });

useSeoMeta({
  ogImage: "/images/tixu.jpg",
  description: runtimeConfig.public.siteDescription,
});
</script>
